<template>
    <ListBasicComponent end-point="sizes" title="サイズ" :field="fields"></ListBasicComponent>
</template>

<script>
    import ListBasicComponent from "@/components/views/ListBasicComponent";
    export default {
        name: 'Sizes',
        components: { ListBasicComponent },
        data() {
            return {
                fields: [
                    // { key: 'select', label: '' },
                    { key: 'id', label: 'ID'},
                    { key: 'name', label: '名前' },
                    { key: 'action', label: '' },
                ]
            }
        }
    }
</script>
